<template>
  <div>
    <div v-if="isLoading" class="text-center pt-3">
      <b-spinner label="Loading..." />
    </div>

    <div v-else class="text-center">
      <div data-paperform-id="whoocaptainlog"></div>
      <b-button
        variant="primary"
        class="mb-3 w-75"
        href="https://docs.google.com/spreadsheets/d/1kzFo00OcoaiD7OJomYV-4ohGb1uzwnkkz7onU-5t2EU/edit#gid=0"
        target="_blank"
      >
        View Daily Logs
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BSpinner,
    BButton,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    this.surveyLaunch();
    this.isLoading = false;
  },
  methods: {
    surveyLaunch() {
      const script = document.createElement("script");
      script.src = "https://paperform.co/__embed.min.js";
      document.body.appendChild(script);
    },
  },
};
</script>

<style>
</style>
